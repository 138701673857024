import React from 'react'
import { IRS_CORNER } from '../../components/constant'
import Layout from '../../components/layout'
import PageStructure from '../../components/pagestructure'
import SEO from '../../components/seo'
import IMGTraining from '../../images/new/training1.jpeg'
const TrainingOpportunities = () => {
  return (
    <div>
      <Layout>
        <SEO title="Training Opportunities" />
        <PageStructure
          image={IMGTraining}
          title="Training Opportunities"
          desc="TESTIG"
          mode={IRS_CORNER}
        />
      </Layout>
    </div>
  )
}

export default TrainingOpportunities
